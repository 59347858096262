.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;

  .sidebar {
    min-width: 250px;
    max-width: 250px;
    margin-left: -250px;
    min-height: 100vh;
    background-color: $blue;
    transition: all 0.4s ease-out;

    &.active {
      margin-left: 0;
      transition: all 0.4s ease-in;
    }
  }
}
