// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.
//
$buurtzorg-select-padding-y: 1rem !default;
$buurtzorg-select-bg-size: 30px 26px !default; // In pixels because image dimensions
$buurtzorg-select-indicator-padding: 2rem !default; // Extra padding to account for the presence of the background-image based indicator
$buurtzorg-select-indicator-color: $white;
$buurtzorg-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='26' height='28'><path fill='white' d='M2 12h6V2h12V12h6L14 24z'/></svg>") !default;
$buurtzorg-select-background: escape-svg($buurtzorg-select-indicator) no-repeat
  right $custom-select-padding-x center / $buurtzorg-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

.buurtzorg-select {
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  height: $custom-select-height;
  padding: $custom-select-padding-y
    ($custom-select-padding-x + $buurtzorg-select-indicator-padding)
    $custom-select-padding-y $custom-select-padding-x;
  font-family: $custom-select-font-family;
  @include font-size($custom-select-font-size);
  font-weight: $custom-select-font-weight;
  line-height: $custom-select-line-height;
  color: $white; //  $custom-select-color;
  vertical-align: middle;
  background: $blue $buurtzorg-select-background; // $custom-select-bg $custom-select-background;
  border: $custom-select-border-width solid $custom-select-border-color;
  @include border-radius($custom-select-border-radius, 0);
  @include box-shadow($custom-select-box-shadow);
  appearance: none;

  &:focus {
    border-color: $custom-select-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      box-shadow: $custom-select-box-shadow, $custom-select-focus-box-shadow;
    } @else {
      box-shadow: $custom-select-focus-box-shadow;
    }

    &::-ms-value {
      // For visual consistency with other platforms/browsers,
      // suppress the default white text on blue background highlight given to
      // the selected option text when the (still closed) <select> receives focus
      // in IE and (under certain conditions) Edge.
      // See https://github.com/twbs/bootstrap/issues/19398.
      color: $input-color;
      background-color: $input-bg;
    }
  }

  &[multiple],
  &[size]:not([size='1']) {
    height: auto;
    padding-right: $custom-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $custom-select-disabled-color;
    background-color: $custom-select-disabled-bg;
  }

  // Hides the default caret in IE11
  &::-ms-expand {
    display: none;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $custom-select-color;
  }
}

.buurtzorg-select-sm {
  height: $custom-select-height-sm;
  padding-top: $custom-select-padding-y-sm;
  padding-bottom: $custom-select-padding-y-sm;
  padding-left: $custom-select-padding-x-sm;
  @include font-size($custom-select-font-size-sm);
}

.buurtzorg-select-lg {
  height: $custom-select-height-lg;
  padding-top: $custom-select-padding-y-lg;
  padding-bottom: $custom-select-padding-y-lg;
  padding-left: $custom-select-padding-x-lg;
  @include font-size($custom-select-font-size-lg);
}

.buurtzorg-select {
  @include transition($custom-forms-transition);
}
