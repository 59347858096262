// Vacature Filter
.buurtzorg-filter-container {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 1.5rem;
  bottom: 0;
  min-height: $buurtzorg-filter-container-height;
  background-color: $blue;

  .buurtzorg-filter {
    // display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    padding: 1rem;
    width: 100%;
    height: 100%;
    background: $white;
    color: $blue;
  }
}

.buurtzorg-baangenerator {
  text-transform: uppercase;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
