// Variables
$buurtzorg-filter-container-height: 10%;

// Custom SCSS
html,
body {
  height: 100%;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
}

// Responsive typography
// html {
//   font-size: 0.6rem;
// }

// @include media-breakpoint-up(sm) {
//   html {
//     font-size: 0.6rem;
//   }
// }

// @include media-breakpoint-up(md) {
//   html {
//     font-size: 0.8rem;
//   }
// }

// @include media-breakpoint-up(lg) {
//   html {
//     font-size: 1rem;
//   }
// }

[role='main'] {
  padding-top: 5rem; /* Space for fixed navbar */
}

[role='button'] {
  cursor: pointer;
}

.buurtzorg-image-header {
  // position: relative;
  width: 100%;
  height: calc(100% - #{$buurtzorg-filter-container-height});
  background: url('/assets/images/header1.png');
  background-position: center;
  background-size: cover;
}

.buurtzorg-image-footer {
  position: relative;
  height: 400px;
  background: url('/assets/images/subfooter.jpg');
  background-position: center;
  background-size: cover;
}

.buurtzorg-logo {
  position: absolute;
  padding: 4px 16px;
  text-align: center;
  left: 0;
  top: 30px;
  width: 200px;
  background-color: $blue;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 550;
  color: white;
}

.buurtzorg-quote {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  // width: 30%;
  // right: 20px;
  line-height: 2.4rem;
  color: white;

  .buurtzorg-quote-title {
    width: 350px;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4);
  }

  .buurtzorg-quote-author {
    width: 350px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2rem;
    margin-top: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  }
}

.background-image1 {
  background-image: url('/assets/images/buurtzorg-city-view.jpg');
  background-position: center;
  background-size: cover;
}

.bg-accent-blue {
  background-color: #19a7ff;
}

.bg-accent-purple {
  background-color: #6c44c4;
}

.bg-accent-orange {
  background-color: #c24900;
}

a#branding-link {
  color: white;
  text-decoration: none;
}

@media (max-width: 575px) {
  owl-carousel-o {
    max-width: 345px;
  }

  .owl-carousel {
    max-width: 345px;
  }
}

@media (max-width: 480px) {
  owl-carousel-o {
    max-width: 280px;
  }

  .owl-carousel {
    max-width: 280px;
  }
}

.quill-upload-image {
  max-width: 100%;
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

[sortable].desc:before,
[sortable].asc:before {
  content: '';
  display: block;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
    no-repeat;
  background-size: 22px;
  width: 22px;
  height: 22px;
  float: left;
  margin-left: -22px;
}

[sortable].desc:before {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
